import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    height: '100vh',
    minHeight: 568,
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

/*
  Rutas externas que lanza la carcasa de Evo a acciones concretas
*/
const destinos = {
  //crearHucha: '/create/target/familyTarget/',
  crearCajaFuerte: '/home/objetivos/retirement', //'/create/target/retirement/'

  //crearMonedero: '/create/target/nextWish/',

  /*
  crearHucha: '/home/objetivos/familyTarget',
  crearCajaFuerte: '/home/objetivos/retirement',
	*/

  monedero: '/home/objetivos/nextWish',
  hucha: '/home/objetivos/familyTarget',
  cajaFuerte: '/home/objetivos/retirement',
};

class Login2 extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.search.substr(1));
    const search64 = this.props.location.search.substr(1);
    const search = atob(search64);
    const params = new URLSearchParams(search);
    const usuarioBE = params.get('usuarioBE');
    const identificadorUnico = params.get('identificadorUnico');
    const origen = params.get('origen');
    const destino = params.get('destino');
    this.state = {
      usuarioBE,
      identificadorUnico,
      origen,
      destino,
    };
  }

  async componentDidMount() {
    const data = {
      payload: {
        usuarioBE: this.state.usuarioBE,
        identificadorUnico: this.state.identificadorUnico,
      },
    };
    const login = await fetch(this.props.baseUrl + 'identityProviders/evo', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (login.status === 200) {
      const data = await login.json();
      console.log('Login', data);
      this.props.fetchToken(data, this.props.baseUrl);
      if (this.state.destino === 'default' || !destinos[this.state.destino]) {
        this.props.history.push(this.props.redirector);
      } else {
        this.props.history.push(destinos[this.state.destino]);
      }
    } else {
      console.log('error en el login: ', login.status);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img
          alt='evo'
          src='https://www.evobanco.com/.galleries/home/logoEvo_w_.png'
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Login2);
